.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #f9fafb, #e9ecf3); /* Subtle gradient for the background */
    padding: 40px;
    min-height: 100vh; /* Ensures full screen coverage */
  }
  
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px; /* Increased spacing for better layout */
  }
  
  .profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border: 3px solid #fff; /* White border for a cleaner look */
  }
  
  .profile-name {
    margin-top: 20px;
    font-size: 26px;
    font-weight: 700;
    color: #2c3e50; /* Richer color for text */
    letter-spacing: 0.5px; /* Improved readability */
  }
  
  .profile-role {
    font-size: 18px;
    color: #7f8c8d; /* Neutral gray for secondary text */
  }
  
  .meeting-options {
    display: flex;
    flex-direction: column;
    gap: 20px; /* More spacing between options for better clarity */
  }
  
  .meeting-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 28px;
    border: 1px solid #dfe6e9; /* Soft border */
    border-radius: 12px; /* Increased roundness for a modern feel */
    background-color: #ffffff;
    text-decoration: none;
    color: #2c3e50;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for card effect */
  }
  
  .meeting-option:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
    transform: translateY(-4px); /* Subtle lift effect */
  }
  
  .meeting-option h3 {
    font-size: 20px;
    margin: 0;
    font-weight: 600;
    color: #34495e; /* Slightly darker for emphasis */
  }
  
  .meeting-duration {
    display: flex;
    align-items: center;
    gap: 10px; /* Increased spacing for better alignment */
    font-size: 16px;
    color: #95a5a6; /* Softer gray for icon and text */
  }
  
  .meeting-duration .clock-icon {
    width: 20px; /* Larger size for better visibility */
    height: 20px;
    content: url("https://via.placeholder.com/20"); /* Replace with a proper clock icon URL */
  }
  