.third-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background: linear-gradient(180deg, #3c0063 0%, #8e2be2 100%);
    color: white;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .content {
    max-width: 600px;
    padding: 20px;
  }
  
  .icon img {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
  }

  .icon{
    display: flex;
    justify-content: center;
  }
  
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 15px;
  }
  
  h1 .highlight {
    color: #d98ffb;
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  
  .cta-button {
    background-color: white;
    color: #8e2be2;
    border: none;
    border-radius: 25px;
    padding: 10px 25px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #d98ffb;
    color: #3c0063;
  }
  
  .third-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    /* background: url("../../../assets/rotate.svg") repeat; */
    opacity: 0.1;
    z-index: 1;
  }
  
  .content {
    position: relative;
    z-index: 2;
  }
  

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
  
  .icon img {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    animation: rotate 5s linear infinite;
  }
  