.hero-section {
    text-align: center;
    padding: 50px;
    background: linear-gradient(135deg, #300048, #43007c);
    color: white;
    font-family: "Arial", sans-serif;
  }
  
  .tagline .badge {
    display: inline-block;
    background: transparent;
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    margin-bottom: 20px;
    border: 1px solid lightgrey;
  }
  
  .main-heading {
    font-size: 3rem;
    font-weight: bold;
    margin: 20px 0;
  }
  
  .description {
    font-size: 1.2rem;
    margin: 20px 0;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .buttons {
    margin-top: 30px;
  }
  
  .github-btn,
  .start-btn {
    padding: 10px 20px;
    font-size: 16px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .github-btn {
    background: #333;
    color: white;
  }
  
  .start-btn {
    background: #fff;
    color: black;
  }
  
  .github-btn:hover {
    background: #555;
  }
  
  .start-btn:hover {
    background: grey;
  }
  