.second-container-unique {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .get-started-unique {
    background-color: #f9f0ff;
    color: #7c4dff;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
  }
  
  .main-heading-unique {
    font-size: 36px;
    color: #000;
    margin: 20px 0;
  }
  
  .description-unique {
    font-size: 16px;
    color: #666;
    margin-bottom: 40px;
  }
  
  .steps-container-unique {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .step-card-unique {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    width: 250px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .step-header-unique {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .step-number-unique {
    font-size: 18px;
    font-weight: bold;
    background-color: #f9f0ff;
    color: #7c4dff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
  }
  
  .step-time-unique {
    background-color: #ffe4ff;
    color: #d500f9;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
  }
  
  .icon-placeholder-unique {
    font-size: 48px;
    color: #7c4dff;
    margin: 10px 0;
  }
  
  .step-title-unique {
    font-size: 18px;
    color: #000;
    margin-bottom: 8px;
  }
  
  .step-description-unique {
    font-size: 14px;
    color: #666;
  }
  
  .setup-footer-unique {
    margin-top: 40px;
  }
  
  .total-time-unique {
    font-size: 16px;
    color: #7c4dff;
    margin-bottom: 10px;
  }
  
  .setup-button-unique {
    background-color: #7c4dff;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .setup-button-unique:hover {
    background-color: #6200ea;
  }
  