.pricing-container {
    padding: 60px 20px;
    background: linear-gradient(135deg, #7b1fa2, #c000fa);
    text-align: center;
    color: #fff;
  }
  
  .inner-container {
    max-width: 900px;
    margin: 0 auto;
  }
  
  .pricing-header {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .pricing-subheader {
    font-size: 1.5rem;
    margin-bottom: 50px;
    color: #e0e0e0;
  }
  
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 30px;
  }
  
  .pricing-card {
    padding: 30px;
    border-radius: 10px;
    background: #fff;
    color: #333;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .free-card {
    border-top: 4px solid #4caf50;
  }
  
  .enterprise-card {
    border-top: 4px solid #ff9800;
  }
  
  .pricing-title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .pricing-highlight {
    font-size: 1.2rem;
    color: #888;
    margin-bottom: 15px;
  }
  
  .pricing-price {
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 20px;
    color: #333;
  }
  
  .pricing-description {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .pricing-features {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #555;
  }
  
  .feature-item svg {
    margin-right: 12px;
    color: #4caf50;
  }
  
  .pricing-button {
    display: inline-block;
    padding: 12px 30px;
    font-size: 1.1rem;
    font-weight: 700;
    color: #fff;
    background: #4caf50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .pricing-button:hover {
    background: #388e3c;
  }
  