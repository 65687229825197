/* Main navigation container */
.nav-element-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(160deg, #ffffff 80%, rgb(62, 154, 241) 10%);
    padding: 15px 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Logo styling */
  .logo-element {
    width: 120px; /* Increased size for better visibility */
    border-radius: 10px;
  }
  
  /* Navigation elements container */
  .nav-elements {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    gap: 30px;
  }
  
  /* Style for navigation links */
  .nav-element {
    font-size: 18px;
    color: rgb(7, 4, 4);
    font-weight: 600;
    transition: color 0.3s ease;
    margin: 0px;
  }
  
  .link-element {
    text-decoration: none;
  }
  
  .nav-element:hover {
    color: #3373ea; /* Hover effect */
  }
  
  /* SignIn button */
  .signin-button-header {
    padding: 10px 20px;
    color: #ffffff;
    border-radius: 30px;
    background-color: transparent;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #ffffff;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .signin-button-header:hover {
    background-color: #ffffff;
    color: #3373ea;
    border: 1px solid #3373ea;
  }
  
  .book-demo-button {
    padding: 10px 30px;
    background-color: #3373ea;
    color: #ffffff;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .book-demo-button:hover {
    background-color: #255fba;
    transform: translateY(-2px);
  }
  
  .book-demo-button:focus {
    outline: none;
  }
  
  /* For responsive adjustments */
  @media (max-width: 768px) {
    .nav-elements {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
  
    .logo-element {
      width: 80px; /* Adjust logo size for small screens */
    }
  }
  